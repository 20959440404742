import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../images/MM24LogoTpBg.png";
import Logotop from "../images/MM24LogoTOP.png";
import Logobottom from "../images/MM24LogoBottom.png";
import "../styles/Navbar.css";
import { VscChromeClose } from "react-icons/vsc";
import { FiMenu } from "react-icons/fi";
import { IconContext } from "react-icons";
import ScrollToTop from "./ScrollToTop";

const ACTIVE_COLOR = "#0c5298";

function Navbar() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);
  const location = useLocation();

  const activator = (path) => ({
    color: location.pathname === path && ACTIVE_COLOR,
  });

  return (
    <div>
      <div className="Navbar">
        
          <ScrollToTop />
          <div className="navlogo">
            <Link to="/">
              <img src={Logotop} alt="MumbaiMUN Logo" />
            </Link>
          </div>
          <div className="logoname">
            <Link to="/">
              <img src={Logobottom} alt="MumbaiMUN LogoName" />
            </Link>
          </div>
        
          <IconContext.Provider
            value={{ color: "rgb(90, 90, 90)", className: "global-class-name" }}
          >
            <button
              className="menuToggler"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {navbarOpen ? <VscChromeClose /> : <FiMenu />}
            </button>
          </IconContext.Provider>

          <ul className={`Navbar__links ${navbarOpen ? "showLinks" : ""}`}>
            <Link style={activator("/")} to="/">
              Home
            </Link>
            <Link style={activator("/about")} to="/about">
              About Us
            </Link>
            <Link style={activator("/schedule")} to="/schedule">
              Schedule
            </Link>
            {/* <Link style={activator("/conference")} to="/conference">
              Conference
              </Link> */}
            {/* <Link to="https://drive.google.com/drive/folders/1GZXa3LEpbJuKZDvnByYo_ZcJgjrMOw9r" target="_blank">
              Study Guides
            </Link>
            <Link to="https://docs.google.com/spreadsheets/d/1VdH1rkY-NQjTFFx4Qt_C3lxCAYYA0O70bwIOJXFKd3Q/edit?gid=1801698655#gid=1801698655" target="_blank">
              Portfolio Matrix
            </Link> */}
            <Link style={activator("/secretariat")} to="/secretariat">
              Secretariat
            </Link>
            {/* <Link style={activator("/ExecutiveBoard")} to="/ExecutiveBoard">
              Executive Board
            </Link> */}
            <Link style={activator("/sponsors")} to="/sponsors">
              Sponsors
            </Link>

            {/* registration nav */}
            <div className="register-menu desktop-only">
              <span className="dropdown-toggle">Register</span>
              <ul className="dropdown-content">
                <p>Registrations have Closed</p>
                {/* <li>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdNbHmtQluFNhKgtSlK0Kzmf7z01iL-Dvc7E4ZtnzTYStPuxg/viewform"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Delegate
                  </a>
                </li>
                <li>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdyFp8NdIvygEIbx5mCXMJ1v4Rwt1jK5TNEch8NieXrO5iItg/viewform"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Delegation Team
                  </a>
                </li>
                <li>
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSf5rFttu8e_P2OiFTBCudTUX6le3_vf58NMF5fMDCkdW3fGWg/closedform"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Executive Board
                  </a>
                </li> */}
              </ul>
            </div>

            <div className={`register-menu mobile-only${registerOpen ? " open" : ""}`}
              onClick={() => setRegisterOpen(!registerOpen)}
            >
              <span className="dropdown-toggle">
                Register <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor"
                    style={{ marginLeft: '0px', verticalAlign: 'middle' }}
                  >
                    <path d="M4 6l4 4 4-4H4z" />
                  </svg>
              </span>
              {registerOpen && (
                <ul className="dropdown-content">
                  <p>Registrations have closed</p>
                  {/* <li>
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSdNbHmtQluFNhKgtSlK0Kzmf7z01iL-Dvc7E4ZtnzTYStPuxg/viewform"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Delegate
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSdyFp8NdIvygEIbx5mCXMJ1v4Rwt1jK5TNEch8NieXrO5iItg/viewform"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Delegation Team
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSf5rFttu8e_P2OiFTBCudTUX6le3_vf58NMF5fMDCkdW3fGWg/closedform"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Executive Board
                    </a>
                  </li> */}
                </ul>
              )}
            </div>

            {/* Resources nav */}
            <div className="resources-menu desktop-only">
              <span className="dropdown-toggle">Resources</span>
              <ul className="dropdown-content">
                <li>
                  <a
                    href="https://drive.google.com/drive/folders/17qHKdyRgzR0vEeekEE9SseFe2OkFAIcE"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Study Guides
                  </a>
                </li>
                <li>
                  <a
                    href="https://docs.google.com/spreadsheets/d/1VdH1rkY-NQjTFFx4Qt_C3lxCAYYA0O70bwIOJXFKd3Q/edit?gid=1801698655#gid=1801698655"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Portfolio Matrix
                  </a>
                </li>
                <li>
                  <a
                    href="https://drive.google.com/drive/folders/1S88lIDUuwjEvGj0tCVMavwo2x_Ab3oSR"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Other Resources
                  </a>
                </li>
              </ul>
            </div>

            <div
              className={`resources-menu mobile-only ${resourcesOpen ? "open" : ""}`}
              onClick={() => setResourcesOpen(!resourcesOpen)}
            >
              <span className="dropdown-toggle">Resources
              <svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor"
                    style={{ marginLeft: '0px', verticalAlign: 'middle' }}
                  >
                    <path d="M4 6l4 4 4-4H4z" />
                  </svg>
              </span>
              {resourcesOpen && (
              <ul className="dropdown-content">
                <li>
                  <a
                    href="https://drive.google.com/drive/folders/17qHKdyRgzR0vEeekEE9SseFe2OkFAIcE"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Study Guides
                  </a>
                </li>
                <li>
                  <a
                    href="https://docs.google.com/spreadsheets/d/1VdH1rkY-NQjTFFx4Qt_C3lxCAYYA0O70bwIOJXFKd3Q/edit?gid=1801698655#gid=1801698655"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Portfolio Matrix
                  </a>
                </li>
                <li>
                  <a
                    href="https://drive.google.com/drive/folders/1S88lIDUuwjEvGj0tCVMavwo2x_Ab3oSR"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Other Resources
                  </a>
                </li>
              </ul>
              )}
            </div>

            <Link style={activator("/committees")} to="/committees">
              Committees
            </Link>
          </ul>
        
      </div>
      <div className="spacer"></div>
    </div>
  );
}

export default Navbar;