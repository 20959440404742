import React from "react";
import "../styles/App.css";
// import hero from "../images/hero.gif";
import logohero from "../images/logo.jpg";
import OCpic from "../images/OCphoto.JPG"
import Navbar from "../components/Navbar";
import Timer from "../components/Timer";
import Footer from "../components/Footer";
// import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

export default function Index() {
  return (
    <div>
      <Navbar />
      <div className="hero">
        <div className="hero__banner">
          <img src={logohero} alt="hero banner" />
        </div>
        <div className="hero-wrapper">
          <div className="hero__timer">
            <Timer />
          </div>
        </div>
      </div>
      <div className="about-event">
        <div className="about-event__text">
          <div className="about-event__text--header">
            <h5>Mumbai MUN 2024</h5>
            <h2>About The Event</h2>
          </div>
          <div className="about-event__text--body">
            <p>
              A debonair personification of international diplomacy, Mumbai MUN
              has been a conduit, transcending academia into pertinent
              leadership. An assorted collection of UN committees to pursue, it
              proffers an engaging opportunity for delegates vying for
              meaningful deliberation. Mumbai MUN is back again, with its
              eleventh installment in 2024 as a three day conference on the{" "}
              <b>
                <u>8th, 9th and 10th of November.</u>
              </b>
            </p>
          </div>
        </div>
        <div>
          <a href="#" className="priority disabled">
            <span className="original-text">Click Here For Registrations</span>
            <span className="hover-text">Registrations are Closed</span>
          </a>
        </div>
        <div>
          <a href="#" className="priority-2 disabled">
            <span className="original-text">Click Here For Delegation</span>
            <span className="hover-text">Delegation Registrations are Closed</span>
          </a>
        </div>
        {/* <div>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSdNbHmtQluFNhKgtSlK0Kzmf7z01iL-Dvc7E4ZtnzTYStPuxg/viewform" 
             target="_blank" rel="noopener noreferrer" className="priority">
            Click Here For Registrations
          </a>
        </div>
        <div>
          <a href="https://bit.ly/MumbaiMUN2024-Delegation-Registration" 
             target="_blank" rel="noopener noreferrer" className="priority-2">
            Click Here For Delegation
          </a>
        </div> */}
        <div className="event-venue">
          <div className="event-venue__text">
            <h5>Mumbai MUN 2024</h5>
            <h2>Event Venue</h2>
            <p>
              Mumbai MUN will take place at MPSTME, NMIMS Vile Parle West,
              Mumbai, Maharashtra, India.
            </p>
          </div>
          <div className="google-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.9937549343986!2d72.8345230793457!3d19.107929900000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c835cc4a463d%3A0xdfc96c498accd27a!2sMUN%20Society%20MPSTME!5e0!3m2!1sen!2sin!4v1724579067785!5m2!1sen!2sin/maps/embed?pb=!1m14!1m8!1m3!1d15079.794555786544!2d72.837453!3d19.109909!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9b888ae67fd%3A0xe0b9538d623ac5d2!2sMukesh%20Patel%20School%20of%20Technology%20Management%20%26%20Engineering%2C%20Mumbai!5e0!3m2!1sen!2sin!4v1723753172311!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Event Location"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
