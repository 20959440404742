import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

import Index from "./pages/Index";
import About from "./pages/About";
import Committees from "./pages/Committees";
import Conference from "./pages/Secretariat";
// import Events from "./pages/Events";
import Schedule from "./pages/Schedule";
import Register from "./pages/Register";
import Secretariat from "./pages/Secretariat";
// import ExecutiveBoard from "./pages/ExecutiveBoard";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Priority from "./pages/Priority";
import PrivacyPolicy from "./pages/Privacy";
import Sponsors from "./pages/Sponsors";
import { Navigate } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/schedule",
    element: <Schedule />,
  },
  // {
  //   path: "/register",
  //   element: <Register />,
  // },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/committees",
    element: <Committees />,
  },
  {
    path: "/secretariat",
    element: <Secretariat />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/priority",
    element: <Priority />,
  },
  {
    path: "/sponsors",
    element: <Sponsors />,
  },  
  // {
  //   path: "/ExecutiveBoard",
  //   element: <ExecutiveBoard />,
  // },
  
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
