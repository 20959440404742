import React from "react";
import "react-tabs/style/react-tabs.css";

import Navbar from "../components/Navbar";
import Timer from "../components/Timer";
import Footer from "../components/Footer";
// Panelists Images
// import Aayush from "../images/panelists/aayush.png";
// import Amar from "../images/panelists/amar.png";
// import Harshil from "../images/panelists/harshil.png";
import formalsocials from "../images/events/formalsocials-temporary.png";
import informalsocials from "../images/events/informalsocials-temporary.png";
import scheduleday1 from "../images/day1.jpg";
import scheduleday2 from "../images/day2.jpg";
import scheduleday3 from "../images/day3.jpg";

export default function Events() {
  return (
    <div>
      <Navbar></Navbar>
      <div className="hero">
        <div className="hero-wrapper">
          <div className="hero__timer">
            <Timer />
          </div>
        </div>
      </div>
      <div className="about-event" id="about">
        <div className="about-event__text">
          <div className="about-event__text--header">
            <h5>Mumbai MUN 2024</h5>
            <h2>Events</h2>
          </div>

          <h2 className="event-headings">Mumbai MUN Schedule</h2>
          <div className="events-schedule">
            <div className="eventstab">
              <h2>8th November</h2>
              <ul className="schedule-text">
                <li className="schedule-items">
                  <span className="schedule-time">9:30am - 11:00am:</span>
                  <span className="schedule-event">Registration</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">11:00am - 12:30pm:</span>
                  <span className="schedule-event">Opening Ceremony</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">12:30pm - 2:00pm:</span>
                  <span className="schedule-event">Lunch</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">2:00pm - 5:00pm:</span>
                  <span className="schedule-event">Committee Session - 1</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">5:00pm - 5:30pm:</span>
                  <span className="schedule-event">High Tea</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">5:30pm - 7:30pm:</span>
                  <span className="schedule-event">Formal Socials</span>
                </li>         
              </ul>
              {/* <img src={scheduleday1} class="schedule-pic" alt="schedulepic"></img> */}
            </div>
            {/* <div className="vertical-line"></div> */}
            <div className="eventstab">
              <h2>9th November</h2>
              <ul className="schedule-text">
              <li className="schedule-items">
                  <span className="schedule-time">8:00am - 9:00am:</span>
                  <span className="schedule-event">Breakfast</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">9:00am - 12:00pm:</span>
                  <span className="schedule-event">Committee Session - 2</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">12:00pm - 1:00pm:</span>
                  <span className="schedule-event">Lunch</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">1:00pm - 4:00pm:</span>
                  <span className="schedule-event">Committee Session - 3</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">4:00pm - 5:30pm:</span>
                  <span className="schedule-event">High Tea</span>
                </li>       
              </ul>
              {/* <img src={scheduleday2} class="schedule-pic" alt="schedulepic"></img> */}
            </div>
            {/* <div className="vertical-line"></div> */}
            <div className="eventstab">
              <h2>10th November</h2>
              <ul className="schedule-text">
              <li className="schedule-items">
                  <span className="schedule-time">8:00am - 9:00am:</span>
                  <span className="schedule-event">Breakfast</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">9:00am - 12:00pm:</span>
                  <span className="schedule-event">Committee Session - 4</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">12:00pm - 1:00pm:</span>
                  <span className="schedule-event">Lunch</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">1:00pm - 4:00pm:</span>
                  <span className="schedule-event">Committee Session - 5</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">4:00pm - 5:00pm:</span>
                  <span className="schedule-event">High Tea</span>
                </li>
                <li className="schedule-items">
                  <span className="schedule-time">5:00pm - 7:30pm:</span>
                  <span className="schedule-event">Closing Ceremony</span>
                </li>
              </ul>
              {/* <img src={scheduleday3} class="schedule-pic" alt="schedulepic"></img> */}
            </div>
          </div>

          <div className="all-events">
            <div className="events-item">
              <div className="events-text">
                <h2 className="event-headings">Formal Socials</h2>
                <p>At Mumbai MUN's formal socials last year, the evening was filled with elegance. The highlight of the event was a live performance by a talented singer, who captivated the audience with his beautiful hindi songs. Members of the organizing committee, executive board and participants enjoyed an atmosphere of sophistication, perfectly suited for networking and discussions. The event concluded with a prize distribution for all the committees, where outstanding delegates were recognized for their contributions throughout the conference.</p>
              </div>
              <div className="events-img">
                <img src={formalsocials} alt="temp"></img>
              </div>
            </div>
            <div className="events-item">
            <div className="events-text">
                <h2 className="event-headings">Informal Socials</h2>
                <p>The informal social offer a completely different vibe. Last year, delegates, executive board members and members of the organizing committee left behind the formalities and headed to a restrobar to unwind. Laughter, casual conversations, food and drinks created bonds and experiences that the attendees would never forget. Informal socials are the perfect ending to a rigorous yet fun journey of Mumbai MUN, allowing people to connect on a more personal level, making them a part of the Mumbai MUN family.</p>
              </div>
              <div className="events-img">
                <img src={informalsocials} alt="temp"></img>
              </div>
              
            </div>
          </div>

          <div> 
            {/*<VerticalTabs />*/}
           {/* <div class="panelists"> */}
              {/* <h2>Panel Discussion</h2>

              <div class="panelist panelist-1">
                <div class="panelist-info">
                  <h3>Harshil Karia</h3>
                  <h4>Founder, Schbang & Co-founder, Level Supermind</h4>
                  <p>
                    He has conquered the marketing industry. He runs the only
                    global Indian company in the advertisement and communication
                    industry , employing over 1200 individuals, and has achieved
                    profitability in just over 5 years
                  </p>
                </div>
                <img src={Harshil} alt="Portrait of Harshil Karia"></img>
              </div>
              <div class="panelist panelist-2">
                <div class="panelist-info">
                  <h3>Captain Amar Johri</h3>
                  <h4>Director of Aman Aviations</h4>
                  <p>
                    Captain Aman Johri is the CEO of an Aerospace & Defence Firm
                    based in Mumbai. He develops next-generation weapons
                    technology for the Indian Armed Forces. He's also fondly
                    known as India's Tony Stark.
                  </p>
                </div>
                <img src={Amar} alt="Portrait of Amar Johri"></img>
              </div>
              <div class="panelist panelist-3">
                <div class="panelist-info">
                  <h3>Aayush Anand</h3>
                  <h4>Co-founder, Level Supermind</h4>
                  <p>
                    An IIT Madras alumni who worked with ITC, P&G, CERN and has
                    successfully co- founded Level Supermind at the age of 23.
                  </p>
                </div>
                <img src={Aayush} alt="Portrait of Aayush Anand"></img>
              </div> */}

            {/* <h1 className="comingsoon">Coming Soon... </h1> */}
            {/* </div>  */}
            

            
          </div>
        </div>
      </div>
  
      <Footer ></Footer>
    </div>
  );
}
