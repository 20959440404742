import React from "react";

import Navbar from "../components/Navbar";
import Timer from "../components/Timer";
import Footer from "../components/Footer";

export default function Sponsors() {
  return (
    <div>
      <Navbar></Navbar>
      <div className="hero">
        <div className="hero-wrapper">
          <div className="hero__timer">
            <Timer />
          </div>
        </div>
      </div>
      <div className="about-event" id="about">
        <div className="about-event__text">
          <div className="t-event__text--header">
            <h5>Mumbai MUN 2024</h5>
            <h2>Sponsors</h2>
          </div>
          <div className="spon-card-container">
            <div className="spon-cards">
              <div className="spon-card" data-spon="lytads">
                <div className="spon-name">LYTADS</div>
              </div>

              <div className="spon-card" data-spon="lookssalon">
                <div className="spon-name">Looks Salon</div>
              </div>

              <div className="spon-card" data-spon="amaha">
                <div className="spon-name">Amaha</div>
              </div>

              <div className="spon-card" data-spon="ilw">
                <div className="spon-name">ILW</div>
              </div>

              <div className="spon-card" data-spon="sultansdeli">
                <div className="spon-name">Sultan's Deli</div>
              </div>

              <div className="spon-card" data-spon="riseup">
                <div className="spon-name">RiseUp</div>
              </div>

              <div className="spon-card" data-spon="tiivra">
                <div className="spon-name">Tiivra</div>
              </div>

              <div className="spon-card" data-spon="bikaji">
                <div className="spon-name">Bikaji</div>
              </div>

              <div className="spon-card" data-spon="inc5">
                <div className="spon-name">Sqube</div>
              </div>

              <div className="spon-card" data-spon="sqube">
                <div className="spon-name">Inc.5</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
