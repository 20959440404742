// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure the timer has proper spacing and alignment */
.timer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

/* Style the end message */
.started {
  text-align: center;
  width: 100%;
  padding: 2rem;
  font-family: "Unbounded";
  text-transform: uppercase;
  font-size: 2rem;
  background-color: rgba(192, 193, 230, 0.452);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/Timer.css"],"names":[],"mappings":"AAAA,sDAAsD;AACtD;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;AACX;;AAEA,0BAA0B;AAC1B;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,wBAAwB;EACxB,yBAAyB;EACzB,eAAe;EACf,4CAA4C;EAC5C,kCAAkC;AACpC;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":["/* Ensure the timer has proper spacing and alignment */\n.timer {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  gap: 20px;\n}\n\n/* Style the end message */\n.started {\n  text-align: center;\n  width: 100%;\n  padding: 2rem;\n  font-family: \"Unbounded\";\n  text-transform: uppercase;\n  font-size: 2rem;\n  background-color: rgba(192, 193, 230, 0.452);\n  animation: fadeIn 0.5s ease-in-out;\n}\n\n@keyframes fadeIn {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
