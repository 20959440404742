import React from "react";
// Fucking hate how I have to import each image separately and cant import an entire folder instead, but we ball.
//Hi im the person working on the website after the person who wrote the above comment yeah so you couldve used---
//---dynamic import which automatically imports the images but i did not implement cause when it works i dont want to touch it so we ball lmao
// Executive Board Images
// import dhruvthakker from '../images/eb/Dhruv Thakker.JPG'
// import aryaveersingh from "../images/eb/Aryaveer Singh.jpg";
// import marshaabalani from"../images/eb/Marshaa Balani.jpg";
// import yashmudaliyar from "../images/eb/Yash 1.jpeg";
// import athangshinde from "../images/eb/Athang Shinde.JPG";
// import mohilmehra from "../images/eb/Mohil Mehra.JPG"
// import aashayinamdar from "../images/eb/Aashay Inamdar.jpg";
// import manavsingh from "../images/eb/manavsingh.jpg";
// import mohitghimire from "../images/eb/mohitghimire.jpg";
// import dharmildoshi from "../images/eb/dharmildoshi.jpg";
// import natasha from "../images/eb/natasha.jpg";
// import manaksh from "../images/eb/manaksh.jpg";
// import avinashtripathy from "../images/eb/avinashtripathy.jpg";
// import devkyawal from "../images/eb/devkyawal.jpg"
// Leadership Team Images
import siddharth from "../images/secretariat/lt/siddharthmundada.jpg";
import bhagyashree from "../images/secretariat/lt/bhagyashreebirje.jpg";
import manvi from "../images/secretariat/lt/manvi_bhala.jpg";
import krishpajwani from "../images/secretariat/lt/krish.jpg";
import nnisarg from "../images/secretariat/lt/nnisarg.jpg";
// import bhaskar from "../images/secretariat/lt/bhaskar.jpg";
import divyansh from "../images/secretariat/lt/divyansh.jpg";
import neel from "../images/secretariat/lt/neel.jpg";
import siddhi from "../images/secretariat/lt/siddhi.jpg";
import purab from "../images/secretariat/lt/purab.jpg";
import akshat from "../images/secretariat/lt/akshat.jpg";
import jash from "../images/secretariat/lt/jash.jpg";
// import shahima from "../images/secretariat/lt/shahima.jpg";
// import tirth from "../images/secretariat/lt/tirth.jpg";
// import tvisha from "../images/secretariat/lt/tvisha.jpg";

// Secretariat Images
// import anidya from "../images/secretariat/core/anidyasinghbhandari.jpg";
// import veer from "../images/secretariat/core/veer.jpg";
// import darsh from "../images/secretariat/core/darshmashru.jpg";
// import helly from "../images/secretariat/core/hellydesai.jpg";
// import ishika from "../images/secretariat/core/ishikajain.jpg";
// import krish from "../images/secretariat/core/krishmehta.jpg";
// import mehek from "../images/secretariat/core/mehekpatel.jpg";
// import mohit from "../images/secretariat/core/mohitpanpaliya.jpg";
// import naisha from "../images/secretariat/core/naishajain.jpg";
// import radha from "../images/secretariat/core/radhasatere.jpg";
// import disha from "../images/secretariat/core/disha.jpg";
// import dviti from "../images/secretariat/core/dvitimehta.jpg";
// import sanjana from "../images/secretariat/core/sanjanashinde.jpg";
// import vishwaja from "../images/secretariat/core/vishwajabute.jpg";
// import yashbhatia from "../images/secretariat/core/yashbhatia.jpg";
// import shanaya from "../images/secretariat/core/shanayakarkhanis.jpg";
// import yash from "../images/secretariat/core/yashburshe.jpg";
// import freya from "../images/secretariat/core/freya_shah.jpeg";
// import khush from "../images/secretariat/core/khush_trivedi.jpeg";
// import soumya from "../images/secretariat/core/soumya_chaudhary.jpeg";
import anweshad from "../images/secretariat/core/anwesha.jpg";
import kritika from "../images/secretariat/core/kritika.jpg";
import ujjwal from "../images/secretariat/core/ujjwal.jpg";
import mohak from "../images/secretariat/core/mohak.jpg";
import shalini from "../images/secretariat/core/shalinishrivastav.jpg";
import atharva from "../images/secretariat/core/atharva.jpg";
import atharv from "../images/secretariat/core/atharvvijayvargiya.jpg";
import nihar from "../images/secretariat/core/niharchuri.jpg";
import aayush from "../images/secretariat/core/aayushkapoor.jpg";
import mithil from "../images/secretariat/core/mithilsave.jpg";
import anjali from "../images/secretariat/core/anjalipatil.jpg";
import aditri from "../images/secretariat/core/aditrimehta.jpg";
import sanskruti from "../images/secretariat/core/sanskrutishah.jpg";
import krishna from "../images/secretariat/core/krishnasabherwal.jpg";
import ashmi from "../images/secretariat/core/ashmivora.jpg";
import yug from "../images/secretariat/core/yugrokadia.jpg";
import saahil from "../images/secretariat/core/saahilmadhani.jpg";
import kartika from "../images/secretariat/core/kartikadhonde.jpg";
import kshitij from "../images/secretariat/core/kshitijjhalak.jpg";
import naman from "../images/secretariat/core/namanbhatia.jpg";
import yukti from "../images/secretariat/core/yuktibagaria.jpg";
import rajveer from "../images/secretariat/core/rajveerjiwrajka.jpg";
import abhishek from "../images/secretariat/core/abhishekrajput.jpeg";
import shahzaib from "../images/secretariat/core/shahzaibsiddiqui.jpg";
import rashi from "../images/secretariat/core/rashiviz.jpg";
import aranck from "../images/secretariat/core/aranckjomraj.jpg";
import urja from "../images/secretariat/core/urjainnani.jpg";
import tanishkka from "../images/secretariat/core/tanishkkarathod.jpg";
import pankaj from "../images/secretariat/core/pankajmirchandani.jpg";
import ivan from "../images/secretariat/core/ivanjoseph.jpg";
import sania from "../images/secretariat/core/saniajain.jpg";
import aaria from "../images/secretariat/core/aariasrivastav.jpg";
//import anuradha from "../images/secretariat/core/anuradhabansal.jpg"
import hrishikesh from "../images/secretariat/core/hrishikeshvartak.jpg";

// Executives Images
// import bdm from "../images/secretariat/executives/bdm.jpg";
// import bdnm from "../images/secretariat/executives/BdExecs.jpg"
// import hospitality from "../images/secretariat/executives/hospitality.jpg";
// import ihc from "../images/secretariat/executives/ihc.jpg";
// import pr from "../images/secretariat/executives/pr.jpg";
// import rnd from "../images/secretariat/executives/rnd.jpg";
// import tech from "../images/secretariat/executives/tech.jpg";
// Placeholder Image
import placeholder from "../images/banner-eb.jpg";
// Components
import Navbar from "../components/Navbar";
import Timer from "../components/Timer";
import Footer from "../components/Footer";

export default function Conference() {
  return (
    <div>
      <Navbar></Navbar>
      <div className="hero">
        <div className="hero-wrapper">
          <div className="hero__timer">
            <Timer />
          </div>
        </div>
      </div>
      <div className="about-event" id="about">
        <div className="about-event__text">
          <div className="about-event__text--header">
            <h5>Mumbai MUN 2024</h5>
            <h2>Secretariat</h2>
          </div>
          <div>
            {/* <div className="executive-board">
              <h2>Executive Board</h2>
              <div className="cards">
                <div className="card aippm">
                  <img src={aryaveersingh} alt="Portrait of Miheer Jain"></img>
                  <div className="info">
                    <h3>Aryaveer Singh</h3>
                    <h4>Chairperson, UNW</h4>
                  </div>
                </div>
                <div className="card aippm">
                  <img
                    src={marshaabalani}
                    alt="Portrait of Kartik Deshwal"
                  ></img>
                  <div className="info">
                    <h3>Marshaa Balani</h3>
                    <h4>Vice-Chairperson, UNW</h4>
                  </div>
                </div>
                <div className="card disec">
                  <img src={yashmudaliyar} alt="Portrait of Tushar Rajput"></img>
                  <div className="info">
                    <h3>Yash Mudaliyar</h3>
                    <h4>Chairperson, UNICEF</h4>
                  </div>
                </div>
                <div className="card disec">
                  <img
                    src={athangshinde}
                    alt="Portrait of Naiessha Nayyar"
                  ></img>
                  <div className="info">
                    <h3>Athang Shinde</h3>
                    <h4>Vice-Chairperson, UNICEF</h4>
                  </div>
                </div>
                <div className="card sochum">
                  <img src={mohilmehra} alt="Portrait of Aarya Singh"></img>
                  <div className="info">
                    <h3>Mohil Mehra</h3>
                    <h4>Chairperson, IMF</h4>
                  </div>
                </div>
                <div className="card sochum">
                  <img src={aashayinamdar} alt="Portrait of Navya Luhadia"></img>
                  <div className="info">
                    <h3>Aashay Inamdar</h3>
                    <h4>Vice-Chairperson, IMF</h4>
                  </div>
                </div>
                <div className="card unsc">
                  <img src={aashayinamdar} alt="Portrait of Manav Singh"></img>
                  <div className="info">
                    <h3>Dhruv Thakker</h3>
                    <h4>Co-Chairperson, UNGA-ESS</h4>
                  </div>
                </div>
                <div className="card unsc">
                  <img src={manavsingh} alt="Portrait of Mohit Ghimire"></img>
                  <div className="info">
                    <h3>Manav Singh</h3>
                    <h4>Co-Chairperson, UNGA-ESS</h4>
                  </div>
                </div>
                <div className="card undp">
                  <img src={dharmildoshi} alt="Portrait of Dharmil Doshi"></img>
                  <div className="info">
                    <h3>Dharmil Doshi</h3>
                    <h4>Administrator, UNDP</h4>
                  </div>
                </div>
                <div className="card undp">
                  <img src={natasha} alt="Portrait of Natasha Sangaonkar"></img>
                  <div className="info">
                    <h3>Natasha Sangaonkar</h3>
                    <h4>Associate Administrator, UNDP</h4>
                  </div>
                </div>
                <div className="card aippm">
                  <img src={avinashtripathy} alt="Portrait of Avinash Tripathy"></img>
                  <div className="info">
                    <h3>Avinash Tripathy</h3>
                    <h4>Moderator, AIPPM</h4>
                  </div>
                </div>
                <div className="card aippm">
                  <img src={devkyawal} alt="Portrait of Dev Kyaval"></img>
                  <div className="info">
                  <h3>Dev Kyaval</h3>
                  <h4>Deputy Moderator, AIPPM</h4>
                  </div>
                </div>
              </div>
              </div> */}
            <div className="secretariat">
              {/* <h2 className="cardhead">Secretariat</h2> */}
              {/* <h3 className="cardheads">Leadership Team</h3> */}
              <div className="cards leadership-team">
              <div className="sec-gen">
                <div className="card">
                  <img
                    src={siddharth}
                    alt="Portrait of Siddharth Mundada"
                  ></img>
                  <div className="info">
                    <h3>Siddharth Mundada</h3>
                    <h4>Secretary General</h4>
                  </div>
                </div>
                <div className="card">
                  <img
                    src={bhagyashree}
                    alt="Portrait of Bhagyashree Birje"
                  ></img>
                  <div className="info">
                    <h3>Bhagyashree Birje</h3>
                    <h4>Secretary General</h4>
                  </div>
                </div>
              </div>
              <div className="director-general">
                <div className="card">
                  <img src={purab} alt="Portrait of Purab Shah"></img>
                  <div className="info">
                    <h3>Purab Shah</h3>
                    <h4>Director General</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={akshat} alt="Portrait of Akshat Mardikar"></img>
                  <div className="info">
                    <h3>Akshat Mardikar</h3>
                    <h4>Director General</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={divyansh} alt="Portrait of Divyansh Agrawal"></img>
                  <div className="info">
                    <h3>Divyansh Agrawal</h3>
                    <h4>Director General</h4>
                  </div>
                </div>
              </div>
              <div className="deputy-sec-gen">
                <div className="card">
                  <img
                    src={nnisarg}
                    alt="Portrait of Nnisarg Gada"
                    style={{ objectFit: "cover", objectPosition: "top" }}></img>
                  <div className="info">
                    <h3>Nnisarg Gada</h3>
                    <h4>Deputy Secretary General</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={siddhi} alt="Portrait of Siddhi Kulkarni"></img>
                  <div className="info">
                    <h3>Siddhi Kulkarni</h3>
                    <h4>Deputy Secretary General</h4>
                  </div>
                </div>
              </div>
              <div className="chief-staff">
                <div className="card">
                  <img src={krishpajwani} alt="Portrait of Krish Pajwani"></img>
                  <div className="info">
                    <h3>Krish Pajwani</h3>
                    <h4>Chief of Staff</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={jash} alt="Portrait of Jash Ladhani"></img>
                  <div className="info">
                    <h3>Jash Ladhani</h3>
                    <h4>Chief of Staff</h4>
                  </div>
                </div>
              </div>
              <div className="chief-info">
                <div className="card">
                  <img src={manvi} alt="Portrait of Manvi Bhala"></img>
                  <div className="info">
                    <h3>Manvi Bhala</h3>
                    <h4>Chief Information Officer</h4>
                  </div>
                </div>
                
                <div className="card">
                  <img src={neel} alt="Portrait of Neel Shah"></img>
                  <div className="info">
                    <h3>Neel Shah</h3>
                    <h4>Chief Information Officer</h4>
                  </div>
                </div>
              </div>
              </div>
              <br />
              {/* <h3 className="cardhead">Departments</h3> */}
              <br />
              {/* <h4 className="cardheads">Business Development & Marketing</h4> */}
              <div className="cards bdm">
                <div className="card">
                  <img src={anweshad} alt="Portrait of Anwesha Dash"></img>
                  <div className="info">
                    <h3>Anwesha Dash</h3>
                    <h4>USG Business Development & Marketing</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={saahil} alt="Portrait of Saahil Madhani"></img>
                  <div className="info">
                    <h3>Saahil Madhani</h3>
                    <h4>USG Business Development & Marketing</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={sania} alt="Portrait of Sania Jain "></img>
                  <div className="info">
                    <h3>Sania Jain</h3>
                    <h4>USG Business Development & Marketing</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={ivan} alt="Portrait of Ivan Joseph "></img>
                  <div className="info">
                    <h3>Ivan Joseph</h3>
                    <h4>USG Business Development & Marketing</h4>
                  </div>
                </div>
                {/* <div className="card">
                  <img src={bdnm} alt="Executives of BD&M"></img>
                  <div className="info">
                    <h3>Executives</h3>
                  </div>
                </div> */}
              </div>
              {/* <h4 className="cardheads">Hospitality</h4> */}
              <div className="cards ihc">
                <div className="card">
                  <img src={rajveer} alt="Portrait of Rajveer Jiwrajka"></img>
                  <div className="info">
                    <h3>Rajveer Jiwrajka</h3>
                    <h4>USG Social Events</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={aaria} alt="Portrait of Aaria Srivastav"></img>
                  <div className="info">
                    <h3>Aaria Srivastav</h3>
                    <h4>USG Social Events</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={urja} alt="Portrait of Urja Innani"></img>
                  <div className="info">
                    <h3>Urja Innani</h3>
                    <h4>USG Hospitality</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={abhishek} alt="Portrait of Abhishek Rajput"></img>
                  <div className="info">
                    <h3>Abhishek Rajput</h3>
                    <h4>USG Hospitality</h4>
                  </div>
                </div>
                {/* <div className="card">
                  <img src={hospitality} alt="Executives of Hospitality"></img>
                  <div className="info">
                    <h3>Executives</h3>
                  </div>
                </div> */}
              </div>
              {/* <h4 className="cardheads">In House Creatives</h4> */}
              <div className="cards cnd">
                <div className="card">
                  <img src={aditri} alt="Portrait of Aditri Mehta"></img>
                  <div className="info">
                    <h3>Aditri Mehta</h3>
                    <h4>USG Creatives</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={atharva} alt="Portrait of Atharva Bapardekar"></img>
                  <div className="info">
                    <h3>Atharva Bapardekar</h3>
                    <h4>USG Creatives</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={mohak} alt="Portrait of Mohak Gharat"></img>
                  <div className="info">
                    <h3>Mohak Gharat</h3>
                    <h4>USG Creatives</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={yug} alt="Portrait of Yug Rokadia"></img>
                  <div className="info">
                    <h3>Yug Rokadia</h3>
                    <h4>USG Editorials</h4>
                  </div>
                </div>
              </div>
              {/* <h4 className="cardheads">Content & Design</h4> */}
              <div className="cards hospi">
                <div className="card">
                  <img src={shalini} alt="potrait of Shalini Shrivastav"></img>
                  <div className="info">
                    <h3>Shalini Shrivastav</h3>
                    <h4>USG Design</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={kartika} alt="potrait of Kartika Dhonde"></img>
                  <div className="info">
                    <h3>Kartika Dhonde</h3>
                    <h4>USG Design</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={kshitij} alt="potrait of Kshitij Jhalak"></img>
                  <div className="info">
                    <h3>Kshitij Jhalak</h3>
                    <h4>USG Design</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={tanishkka} alt="potrait of Tanishkka Rathod"></img>
                  <div className="info">
                    <h3>Tanishkka Rathod</h3>
                    <h4>USG Design</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={shahzaib} alt="potrait of Shahzaib Siddiqui"></img>
                  <div className="info">
                    <h3>Shahzaib Siddiqui</h3>
                    <h4>USG Editorials</h4>
                  </div>
                </div>
                
              </div>
              {/* <h4 className="cardheads">Logistics</h4> */}
              <div className="cards logi">
                <div className="card">
                  <img src={kritika} alt="Portrait of Kritika Kumari"></img>
                  <div className="info">
                    <h3>Kritika Kumari</h3>
                    <h4>USG Operations</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={naman} alt="Portrait of Naman Bhatia"></img>
                  <div className="info">
                    <h3>Naman Bhatia</h3>
                    <h4>USG Operations</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={yukti} alt="Portrait of Yukti Bagaria"></img>
                  <div className="info">
                    <h3>Yukti Bagaria</h3>
                    <h4>USG Operations</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={atharv} alt="Portrait of Atharv Vijayvargiya"></img>
                  <div className="info">
                    <h3>Atharv Vijayvargiya</h3>
                    <h4>USG Operations</h4>
                  </div>
                </div>
                {/* <div className="card">
                  <img src={placeholder} alt="Executives of Logistics"></img>
                  <div className="info">
                    <h3>Executives</h3>
                  </div>
                </div> */}
              </div>
              {/* <h4 className="cardheads">Public Relations</h4> */}
              <div className="cards pr">
                <div className="card">
                  <img src={ashmi} alt="Portrait of Ashmi Vora"></img>
                  <div className="info">
                    <h3>Ashmi Vora</h3>
                    <h4>USG Delegate Affairs</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={sanskruti} alt="Portrait of Sanskruti Shah"></img>
                  <div className="info">
                    <h3>Sanskruti Shah</h3>
                    <h4>USG Delegate Affairs</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={krishna} alt="Portrait of Krishna Sabherwal"></img>
                  <div className="info">
                    <h3>Krishna Sabherwal</h3>
                    <h4>USG Delegate Affairs</h4>
                  </div>
                </div>
                <div className="card">
                  <img
                    src={hrishikesh}
                    alt="Portrait of Hrishikesh Vartak"
                  ></img>
                  <div className="info">
                    <h3>Hrishikesh Vartak</h3>
                    <h4>USG Finance</h4>
                  </div>
                </div>
              </div>
              {/* <h4 className="cardheads">Research & Development</h4> */}
              <div className="cards rnd">
                <div className="card">
                  <img src={ujjwal} alt="Portrait of Ujjwal Sharma"></img>
                  <div className="info">
                    <h3>Ujjwal Sharma</h3>
                    <h4>USG Research & Development</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={rashi} alt="Portrait of Rashi Viz"></img>
                  <div className="info">
                    <h3>Rashi Viz</h3>
                    <h4>USG Research & Development</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={aranck} alt="Portrait of Aranck Jomraj"></img>
                  <div className="info">
                    <h3>Aranck Jomraj</h3>
                    <h4>USG Research & Development</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={pankaj} alt="Portrait of Pankaj Mirchandani"></img>
                  <div className="info">
                    <h3>Pankaj Mirchandani</h3>
                    <h4>USG Research & Development</h4>
                  </div>
                </div>
                {/* <div className="card">
                  <img src={rnd} alt="Executives of RND"></img>
                  <div className="info">
                    <h3>Executives</h3>
                  </div>
                </div> */}
              </div>
              {/* <h4 className="cardheads"> Technology</h4> */}
              <div className="cards tech">
                <div className="card">
                  <img src={anjali} alt="Portrait of Anjali Patil"></img>
                  <div className="info">
                    <h3>Anjali Patil</h3>
                    <h4>USG Technology</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={aayush} alt="Portrait of Aayush Kapoor"></img>
                  <div className="info">
                    <h3>Aayush Kapoor</h3>
                    <h4>USG Technology</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={mithil} alt="Portrait of Mithil Save"></img>
                  <div className="info">
                    <h3>Mithil Save</h3>
                    <h4>USG Technology</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={nihar} alt="Portrait of Nihar Churi"></img>
                  <div className="info">
                    <h3>Nihar Churi</h3>
                    <h4>USG Technology</h4>
                  </div>
                </div>
                {/*<div className="card">
                  <img src={darsh} alt="Portrait of Darsh Mashru"></img>
                  <div className="info">
                    <h3>Darsh Mashru</h3>
                    <h4>USG Technology</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={yash} alt="Portrait of Yash Burshe"></img>
                  <div className="info">
                    <h3>Yash Burshe</h3>
                    <h4>USG Technology</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={placeholder} alt="Portrait of Ansh Malik"></img>
                  <div className="info">
                    <h3>Ansh Malik</h3>
                    <h4>USG Technology</h4>
                  </div>
                </div>
                <div className="card">
                  <img src={tech} alt="Executives of Tech"></img>
                  <div className="info">
                    <h3>Executives</h3>
                  </div>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}