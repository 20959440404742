import React, { useEffect, useState } from "react";
import "../styles/Timer.css";
import Confetti from "react-confetti";

function Timer() {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);

  // Set the deadline
  const deadline = "2024-11-08T11:00:00+05:30";

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    getTime();
    const interval = setInterval(() => getTime(deadline), 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Check if time has ended to trigger confetti
    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      setShowConfetti(true);
    }
  }, [days, hours, minutes, seconds]);

  return (
    <>
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false} // Stops confetti after it falls once
        />
      )}
      {days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0 ? (
        <div className="started">
          <span
            style={{
              color: "#0f4c90",
              fontFamily: "Unbounded",
            }}
          >
            Mumbai MUN '24'
          </span>
          <br />
          has Concluded!
        </div>
      ) : (
        <div className="timer">
          <div className="unit days">
            <div className="time">{days}</div>
            <div className="text">
              {days === 1 ? `Day left` : `Days left`}
            </div>
          </div>
          <div className="unit hours">
            <div className="time">{hours}</div>
            <div className="text">Hours</div>
          </div>
          <div className="unit minutes">
            <div className="time">{minutes}</div>
            <div className="text">Minutes</div>
          </div>
          <div className="unit seconds">
            <div className="time">{seconds}</div>
            <div className="text">Seconds</div>
          </div>
        </div>
      )}
    </>
  );
}

export default Timer;